import variables from "../../../scss/_exports.module.scss";

function Industries() {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317.43 258.99">
            <path
                d="M79.14,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M80.29,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M57.86,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M59.01,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91S47.79,0,51.78,0s7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M36.58,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M37.73,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91S26.51,0,30.5,0s7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M15.3,57.46c-.1,1.08-1.06,1.96-2.15,1.96H5.69c-1.08,0-2.06-.88-2.16-1.96L.01,21.89c-.11-1.08,.44-2.59,1.2-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M16.45,8.9c0,4.92-3.24,8.91-7.23,8.91S2,13.82,2,8.9,5.23,0,9.22,0s7.23,3.99,7.23,8.9Z" fill={variables['stark-dark']} />
            <path
                d="M100.42,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M101.57,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M79.14,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M80.29,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M57.86,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M59.01,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M36.58,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M37.73,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M15.3,124.39c-.1,1.08-1.06,1.96-2.15,1.96H5.69c-1.08,0-2.06-.88-2.16-1.96L.01,88.81c-.11-1.08,.44-2.59,1.2-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M16.45,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M100.42,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M101.57,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M185.79,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M187.82,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M164.51,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M166.54,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M143.23,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M145.26,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M121.95,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M123.98,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M207.07,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M209.1,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M185.79,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M186.94,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M164.51,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M165.66,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M143.23,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M144.38,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M121.95,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M123.1,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M207.07,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M208.22,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M292.99,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M295.02,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M271.71,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.09,0-2.06-.88-2.17-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M273.74,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M250.43,57.46c-.09,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M252.46,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M229.15,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.2-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M231.18,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M314.27,57.46c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M316.29,8.9c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M292.99,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M294.14,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M271.71,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.09,0-2.06-.88-2.17-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M272.86,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M250.43,124.39c-.09,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M251.58,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M229.15,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.2-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M230.3,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M314.27,124.39c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M315.42,75.83c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M79.14,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <ellipse cx="73.06" cy="141.54" rx="7.23" ry="8.9" fill={variables['stark-dark']} />
            <path
                d="M57.86,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M59.01,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M36.58,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M37.73,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M15.3,190.1c-.1,1.08-1.06,1.96-2.15,1.96H5.69c-1.08,0-2.06-.88-2.16-1.96L.01,154.52c-.11-1.08,.44-2.59,1.2-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M16.45,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M100.42,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M101.57,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M79.14,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M80.29,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M57.86,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M59.01,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M36.58,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M37.73,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M15.3,257.02c-.1,1.08-1.06,1.96-2.15,1.96H5.69c-1.08,0-2.06-.88-2.16-1.96L.01,221.45c-.11-1.08,.44-2.59,1.2-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M16.45,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M100.42,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M101.57,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M185.79,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M187.82,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M164.51,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M166.54,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M143.23,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M145.26,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M121.95,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M123.98,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M207.07,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M209.1,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M185.79,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M186.94,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M164.51,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M165.66,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M143.23,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M144.38,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M121.95,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M123.1,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M207.07,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.16-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M208.22,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M292.99,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M295.02,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M271.71,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.09,0-2.06-.88-2.17-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <ellipse cx="266.51" cy="141.54" rx="7.23" ry="8.9" fill={variables['stark-dark']} />
            <path
                d="M250.43,190.1c-.09,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M252.46,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M229.15,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.2-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M231.18,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M314.27,190.1c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M316.29,141.54c0,4.92-3.24,8.9-7.23,8.9s-7.23-3.99-7.23-8.9,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M292.99,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M294.14,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M271.71,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.09,0-2.06-.88-2.17-1.96l-3.52-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M272.86,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M250.43,257.02c-.09,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M251.58,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M229.15,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.2-3.35,0,0,2.13-2.11,8.01-2.11s8.01,2.11,8.01,2.11c.77,.76,1.32,2.27,1.22,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M230.3,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
            <path
                d="M314.27,257.02c-.1,1.08-1.06,1.96-2.15,1.96h-7.46c-1.08,0-2.06-.88-2.17-1.96l-3.51-35.58c-.11-1.08,.44-2.59,1.21-3.35,0,0,2.13-2.11,8.01-2.11s8,2.11,8,2.11c.77,.76,1.32,2.27,1.23,3.35l-3.16,35.57Z"
                fill={variables['stark-dark']} />
            <path d="M315.42,208.47c0,4.92-3.24,8.91-7.23,8.91s-7.23-3.99-7.23-8.91,3.24-8.9,7.23-8.9,7.23,3.99,7.23,8.9Z"
                fill={variables['stark-dark']} />
        </svg>
    )
}

export default Industries;