import InnerBar from './InnerBar';

function Bars() {
    return (
        <div className="bars-container">
            <InnerBar />
            <InnerBar />
            <InnerBar />
            <InnerBar />
            <InnerBar />
            <InnerBar />
        </div>
    )
}

export default Bars;