import React from 'react';
import variables from "../../../scss/_exports.module.scss";

const Bomb = props => {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1240.64 1356.95" style={{width: `${props.blowUp ? '40%' : '30%'}`}}>
            <g id="b">
                <g id="c">
                    <path id="d"
                        d="M698.89,451.2c7.08-3.03,16.16-4.97,25.92-4.56,4.26,26.99-9,47.92-26.16,56.64-9.83,5-22.15,7.77-35.04,5.28-11.63-2.24-20.32-7.21-27.6-14.64-7.44-7.59-11.42-15.98-14.16-28.08-2.47-10.91-10.15-18.61-21.6-20.64-13.36-2.37-23.02,4.75-27.6,13.68-5.27,10.26-1.47,22.31,.72,34.8,7.91-.65,16.98-2.22,25.92-2.64,5.14,27.42,9.6,55.52,14.64,83.04,57.02,3.47,96.67,23.49,127.68,53.28,30.23,29.04,53.67,68.49,58.56,122.16,5.43,59.54-16.49,105.48-42,137.28-26.23,32.69-63.13,58.69-112.8,67.92-27.58,5.13-56.41,4.2-81.12-1.68-24.24-5.77-45.32-15.71-62.88-27.6-36.03-24.4-62.79-58.65-76.56-104.16-7.19-23.77-10.09-53.37-6.48-80.16,3.5-26.01,11.77-48.91,22.32-67.92,21.54-38.81,53.62-66.96,95.76-84.96-4.48-28.16-10.14-55.14-14.4-83.52,8.37-2.27,16.8-4.48,25.68-6.24-1.61-10.14-4.28-19.03-4.08-29.04,.19-9.58,3.21-18.62,7.44-25.68,8.15-13.58,23.34-24.01,42-25.2,15.38-.99,28.14,5.01,36.96,12.48,9.34,7.91,14.82,18.85,17.76,31.68,2.98,12.98,14.04,21.65,27.36,20.64,14.76-1.12,27.36-13.74,23.76-32.16h0Z"
                        stroke={variables['stark-light']} strokeMiterlimit="10" fill={variables['stark-light']}/>
                </g>
            </g>
            <polygon
                points="470.87 393.43 587.48 25.04 755.49 356.52 1126.53 295.56 907.73 578.61 1213.99 688.26 888.91 820.01 1139.79 1119.81 742.24 1061.5 622.98 1331.83 530.22 1085.36 198.93 1207.27 374.38 995.25 55.81 934.29 294.34 831.6 24.01 584.45 323.49 579.15 156.52 234.61 470.87 393.43"
                fill="none" stroke={variables['stark-light']} strokeMiterlimit="10" strokeWidth="19" />
        </svg>
    )
}

export default Bomb;