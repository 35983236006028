import variables from "../../../scss/_exports.module.scss";

function FutureIcon() {
    return(
        <div className="grid-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.07 170.06">
                <path
                    d="M136.47,38.44l-16.5,11.79,21.33,15.04c1.79,1.26,2.85,3.32,2.84,5.51-.01,2.19-1.09,4.24-2.9,5.48l-15.7,10.83,15.7,10.83c1.77,1.22,2.85,3.22,2.89,5.37,.05,2.15-.94,4.2-2.66,5.49l-33.01,24.94c-1.19,.9-2.61,1.35-4.04,1.35-1.03,0-2.06-.24-3.02-.72-2.28-1.15-3.71-3.5-3.68-6.05l.23-22.18-9.11,6.28c-1.15,.79-2.48,1.19-3.81,1.19s-2.66-.39-3.81-1.19l-4.32-2.98-13.54,7.85c1.26,2.17,1.24,4.96-.28,7.16-2.1,3.04-6.28,3.81-9.32,1.71l-24.93-17.2c-1.81-1.25-2.9-3.31-2.9-5.52s1.08-4.27,2.9-5.52l15.7-10.83-15.7-10.83c-1.8-1.25-2.89-3.29-2.9-5.49,0-2.19,1.05-4.25,2.85-5.51l34.37-24.17c2.04-1.44,4.72-1.62,6.94-.47,2.22,1.15,3.61,3.45,3.61,5.95v20.39l7.52-5.19c2.29-1.58,5.32-1.58,7.61,0l24.9,17.17,11.98-8.26-20.01-14.11c-3.03-2.13-3.75-6.31-1.62-9.34,.26-.37,.55-.7,.86-1v-.02l.14-.11c.37-.33,.77-.61,1.19-.85l21.19-15.13c-11.74-9.1-26.46-14.53-42.43-14.53C46.75,15.6,15.6,46.75,15.6,85.03s31.15,69.43,69.43,69.43,69.43-31.15,69.43-69.43c0-17.91-6.82-34.26-18-46.59Z"
                    fill="none" />
                <path
                    d="M85.03,0C38.15,0,0,38.15,0,85.03s38.15,85.03,85.03,85.03,85.03-38.14,85.03-85.03S131.92,0,85.03,0Zm0,154.46c-38.29,0-69.43-31.15-69.43-69.43S46.75,15.6,85.03,15.6c15.97,0,30.68,5.43,42.43,14.53l-21.19,15.13c-.42,.24-.82,.52-1.19,.85l-.15,.11v.02c-.3,.3-.59,.63-.85,1-2.13,3.03-1.41,7.21,1.62,9.34l20.01,14.11-11.98,8.26-24.9-17.17c-2.29-1.58-5.32-1.58-7.61,0l-7.52,5.19v-20.39c0-2.5-1.39-4.79-3.61-5.95-2.22-1.16-4.9-.97-6.94,.47l-34.37,24.17c-1.79,1.26-2.86,3.32-2.85,5.51,.01,2.19,1.09,4.24,2.9,5.49l15.7,10.83-15.7,10.83c-1.81,1.25-2.9,3.31-2.9,5.52s1.08,4.26,2.9,5.52l24.93,17.2c3.05,2.1,7.22,1.33,9.32-1.71,1.52-2.2,1.53-4.99,.28-7.16-.48-.83-1.14-1.58-1.99-2.16l-16.93-11.68,11.89-8.2,20.57,14.19,4.32,2.98c1.15,.79,2.48,1.19,3.81,1.19s2.66-.39,3.81-1.19l9.11-6.28-.23,22.18c-.03,2.55,1.4,4.9,3.68,6.05,.96,.48,1.99,.72,3.02,.72,1.43,0,2.86-.46,4.04-1.35l33.01-24.94c1.72-1.3,2.71-3.34,2.66-5.49-.05-2.15-1.12-4.15-2.89-5.37l-15.7-10.83,15.7-10.83c1.8-1.24,2.88-3.29,2.9-5.48,.01-2.19-1.05-4.25-2.84-5.51l-21.33-15.04,16.5-11.79c11.18,12.33,18,28.68,18,46.59,0,38.29-31.15,69.43-69.43,69.43Zm-24.73-78.26l-3.97,2.74-11.97-8.26,15.94-11.21v16.72Zm24.73-.77l16.9,11.66-16.9,11.66-16.9-11.66,16.9-11.66Zm28.7,19.8l12.25,8.45-14.72,11.12,.19-17.99,2.29-1.58Z"
                    fill={variables['stark-red']} />
            </svg>
        </div>
    )
}

export default FutureIcon;