
import variables from "../../../scss/_exports.module.scss";

function TalentIcon() {
    return(
        <div className="grid-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.06 170.06">
                <path
                    d="M139.65,92.14l-18.66,23.9c-1.31,1.43-3.11,2.16-4.91,2.16-1.61,0-3.23-.58-4.51-1.76-2.71-2.49-2.89-6.71-.4-9.42l11.62-14.88h-16.12c-3.44,26.55-26.51,37.89-39.17,40.22-.41,.07-.81,.11-1.21,.11-.19,0-.37-.03-.56-.05-.26,.03-.52,.05-.78,.05-3.68,0-6.67-2.98-6.67-6.67v-33.66h-13.08v9.88c0,3.68-2.99,6.67-6.67,6.67s-6.67-2.98-6.67-6.67v-33.1c0-3.68,2.99-6.67,6.67-6.67s6.67,2.99,6.67,6.67v9.88h13.08v-11.29c0-3.68,2.99-6.67,6.67-6.67s6.67,2.99,6.67,6.67v11.29h21.63c-3.82-23.05-27.43-29.17-28.52-29.44-3.56-.88-5.76-4.48-4.89-8.05,.87-3.57,4.44-5.77,8.02-4.91,12.6,3.01,35.62,15.63,38.87,42.4h16.07l-11.62-14.88c-2.49-2.71-2.31-6.93,.4-9.42,2.71-2.49,6.93-2.31,9.42,.4l18.66,23.9h14.52c-3.16-35.38-32.95-63.2-69.14-63.2C46.75,15.6,15.6,46.75,15.6,85.03s31.15,69.43,69.43,69.43c35.89,0,65.5-27.37,69.07-62.33h-14.45Z"
                    fill="none" />
                <path d="M93.18,92.14h-21.56v25.06c7.43-3.02,18.85-10.02,21.56-25.06Z" fill="none" />
                <path
                    d="M85.03,0C38.15,0,0,38.15,0,85.03s38.15,85.03,85.03,85.03,85.03-38.14,85.03-85.03S131.92,0,85.03,0Zm0,154.46c-38.29,0-69.43-31.15-69.43-69.43S46.75,15.6,85.03,15.6c36.18,0,65.98,27.83,69.14,63.2h-14.52l-18.66-23.9c-2.49-2.71-6.71-2.89-9.42-.4-2.71,2.49-2.89,6.71-.4,9.42l11.62,14.88h-16.07c-3.25-26.77-26.27-39.39-38.87-42.4-3.57-.85-7.15,1.34-8.02,4.91-.87,3.57,1.33,7.17,4.89,8.05,1.09,.27,24.7,6.39,28.52,29.44h-21.63v-11.29c0-3.68-2.99-6.67-6.67-6.67s-6.67,2.99-6.67,6.67v11.29h-13.08v-9.88c0-3.68-2.99-6.67-6.67-6.67s-6.67,2.99-6.67,6.67v33.1c0,3.68,2.99,6.67,6.67,6.67s6.67-2.98,6.67-6.67v-9.88h13.08v33.66c0,3.68,2.99,6.67,6.67,6.67,.26,0,.52-.02,.78-.05,.19,.02,.37,.05,.56,.05,.4,0,.8-.04,1.21-.11,12.66-2.32,35.73-13.67,39.17-40.22h16.12l-11.62,14.88c-2.49,2.71-2.31,6.93,.4,9.42,1.28,1.18,2.9,1.76,4.51,1.76,1.8,0,3.6-.72,4.91-2.16l18.66-23.9h14.45c-3.57,34.96-33.18,62.33-69.07,62.33Zm-13.42-62.33h21.56c-2.72,15.03-14.14,22.04-21.56,25.06v-25.06Z"
                    fill={variables['stark-red']} />
            </svg>
        </div>
    )
}

export default TalentIcon;