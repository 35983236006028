import variables from "../../../scss/_exports.module.scss";

function Startups() {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 209.5 198.02">
            <path
                d="M44.79,188.56c-.3,3.35-3.29,6.1-6.66,6.1H16.99c-3.37,0-6.39-2.74-6.72-6.09L.04,85.1c-.33-3.35,1.35-8.03,3.74-10.4,0,0,5.98-5.94,23.19-5.94s23.19,5.94,23.19,5.94c2.39,2.37,4.1,7.05,3.8,10.41l-9.18,103.45Z"
                fill={variables['stark-dark']} />
            <path
                d="M48.14,46.74c0,14.4-9.48,26.07-21.17,26.07S5.81,61.14,5.81,46.74,15.29,20.67,26.98,20.67s21.17,11.67,21.17,26.07Z"
                fill={variables['stark-dark']} />
            <path
                d="M120.08,167.88c-.3,3.35-3.29,6.1-6.66,6.1h-21.14c-3.37,0-6.39-2.74-6.72-6.09l-10.22-103.46c-.33-3.35,1.35-8.03,3.74-10.4,0,0,5.98-5.94,23.19-5.94s23.19,5.94,23.19,5.94c2.39,2.37,4.1,7.05,3.8,10.41l-9.18,103.45Z"
                fill={variables['stark-dark']} />
            <path
                d="M123.44,26.07c0,14.4-9.48,26.07-21.17,26.07s-21.17-11.67-21.17-26.07S90.58,0,102.27,0s21.17,11.67,21.17,26.07Z"
                fill={variables['stark-dark']} />
            <path
                d="M200.28,191.93c-.3,3.35-3.29,6.1-6.66,6.1h-21.14c-3.37,0-6.39-2.74-6.72-6.09l-10.22-103.46c-.33-3.35,1.35-8.03,3.74-10.4,0,0,5.98-5.94,23.19-5.94s23.19,5.94,23.19,5.94c2.39,2.37,4.1,7.05,3.8,10.41l-9.18,103.45Z"
                fill={variables['stark-dark']} />
            <path
                d="M203.64,50.11c0,14.4-9.48,26.07-21.17,26.07s-21.17-11.67-21.17-26.07,9.48-26.07,21.17-26.07,21.17,11.67,21.17,26.07Z"
                fill={variables['stark-dark']} />
        </svg>
    )
}

export default Startups;